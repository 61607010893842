import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Table } from './tablesModel';
import { Filters } from './filtersModel';

const initialState: Table[] = [];

const tablesSlice = createSlice({
  name: 'tables',
  initialState,
  reducers: {
    tableAdded(state, action: PayloadAction<Table>) {
      state.push(action.payload);
    },
    tablesLoaded(state, action: PayloadAction<Table[]>) {
      return action.payload;
    },
  },
  selectors: {
    tablesFiltered: (tablesState, filters: Filters, favTables?: string[]) => {
      let tables = tablesState;
      if (filters.tables) {
        switch(filters.tables) {
          case 'all': return tablesState;
          case 'favs': return tablesState.filter(_t => favTables?.includes(_t.tableId));
          case 'slots': return tablesState.filter(_t => _t.category === 'slot')
        }
      }
      return tables;
    }
  }
});

export const { tableAdded, tablesLoaded } = tablesSlice.actions;
export const { tablesFiltered } = tablesSlice.selectors;
export default tablesSlice.reducer;
