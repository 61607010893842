import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import SideNav from "../../components/utils/Sidenav";
import UnityWebGLPlayer from "../../components/gamePlayer/UnityWebGLPlayer";
import RotatePhone from "../../components/utils/RotatePhone";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { Table } from "../../store/tablesModel";

const SlotGame: React.FC = () => {
  const { gameId } = useParams<{ gameId: string }>();
  const tables = useSelector((state: RootState) => state.tables);

  const deviceType = localStorage.getItem("deviceType");
  const [isPortrait, setIsPortrait] = useState(false);
  const [selectedTable, setSelectedTable] = useState<Table>();
  const [preferredOrientation, setPreferredOrientation] = useState<
    "landscape-primary" | "portrait-primary" | undefined
  >(undefined);

  useEffect(() => {
    const checkOrientation = () => {
      setIsPortrait(window.matchMedia("(orientation: portrait)").matches);
    };

    checkOrientation();
    window.addEventListener("resize", checkOrientation);

    return () => {
      window.removeEventListener("resize", checkOrientation);
    };
  }, []);

  useEffect(() => {
    if (gameId) {
      const selectedGame = tables.find((table) => table.slug === gameId);
      if (selectedGame) {
        setSelectedTable(selectedGame);
        setPreferredOrientation(selectedGame.orientation);
      }
    }
  }, [gameId, tables]);

  const isOrientationMismatch =
    deviceType !== "desktop" &&
    ((preferredOrientation === "landscape-primary" && isPortrait) ||
      (preferredOrientation === "portrait-primary" && !isPortrait));

  return (
    <div className="flex h-full w-full">
      {isOrientationMismatch && (
        <RotatePhone orientation={preferredOrientation} />
      )}
      {!isOrientationMismatch && (
        <>
          {deviceType === "desktop" && (
            <div
              className="hidden sm:flex w-[18%] min-w-[240px] max-w-[340px] bg-black/[0.75]"
              style={{ boxShadow: "2px 0 5px rgba(0, 0, 0, 0.5)" }}
            >
              <SideNav />
            </div>
          )}
          <div
            className={`flex w-full h-full p-0 ${
              deviceType === "desktop" && "sm:p-12"
            }`}
          >
            <div
              className={`flex h-full w-full ${
                deviceType === "desktop" &&
                "sm:w-10/12 sm:justify-between sm:mx-auto"
              }`}
            >
              {gameId && selectedTable ? (
                <UnityWebGLPlayer gameId={gameId} table={selectedTable} />
              ) : (
                <>Please Select a game to play</>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default SlotGame;
