import { useState } from "react";
import axios from "axios";
import Button from "../utils/buttons/Button";

interface SignUpFormProps {
  showSpinner: (val: boolean) => void;
  onSuccess: () => void;
}

const SignUpForm: React.FC<SignUpFormProps> = ({ onSuccess, showSpinner }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");

  const validateEmail = (email: string) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const validateMobile = (mobile: string) => {
    const re = /^[0-9]{10}$/;
    return re.test(String(mobile));
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setError("");
    showSpinner(true);

    // Validation checks
    if (password !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }

    if (!validateEmail(email)) {
      setError("Invalid email address");
      return;
    }

    if (!validateMobile(mobile)) {
      setError("Invalid mobile number. Must be 10 digits");
      return;
    }

    try {
      await axios.post(
        "https://backend.inferixai.link/api/signup",
        {
          username,
          password,
          confirm_password: confirmPassword,
          mobile,
          email,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      // Notify parent component of successful signup
      if (onSuccess) {
        onSuccess();
      }
    } catch (err: any) {
      console.error(err);
      if (err.response && err.response.data && err.response.data.error) {
        setError(err.response.data.error);
      } else {
        setError("Failed to sign up");
      }
    } finally {
      showSpinner(false);
    }
  };

  return (
    <div className="flex flex-col w-full">
      <form onSubmit={handleSubmit}>
        <div className="flex flex-col mb-2">
          <input
            className="mt-1"
            type="text"
            id="username"
            value={username}
            placeholder="Username"
            onChange={(e) => setUsername(e.target.value)}
            required
          />
        </div>
        <div className="flex flex-col mt-4 mb-2">
          <input
            className="mt-1"
            type="password"
            id="password"
            value={password}
            placeholder="Password"
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <div className="flex flex-col mt-4 mb-2">
          <input
            className="mt-1"
            type="password"
            id="confirmPassword"
            value={confirmPassword}
            placeholder="Confirm Password"
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
        </div>
        <div className="flex flex-col mt-4 mb-2">
          <input
            className="mt-1"
            type="text"
            id="mobile"
            value={mobile}
            placeholder="Mobile"
            onChange={(e) => setMobile(e.target.value)}
            required
          />
        </div>
        <div className="flex flex-col mt-4 mb-2">
          <input
            className="mt-1"
            type="email"
            id="email"
            value={email}
            placeholder="Email"
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="flex w-full justify-center mt-[40px]">
          <Button classes="w-4/12" type="submit" text={"Sign Up"}></Button>
        </div>
      </form>
      <div className="flex w-full p-2 mt-3 text-red-600 text-sm font-medium justify-center">
        {error && <p>{error}</p>}
      </div>
    </div>
  );
};

export default SignUpForm;
