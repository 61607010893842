import React from "react";
// Refer to App.css button section for styles

interface ButtonProps {
  text: string; // The text to be displayed on the button
  type?: "button" | "submit" | "reset"; // Optional type of the button
  classes?: string; // Optional additional CSS classes for styling
}

const Button: React.FC<ButtonProps> = ({
  text,
  type = "button",
  classes = "",
}) => {
  return (
    <button type={type} className={classes}>
      {text}
    </button>
  );
};

export default Button;
