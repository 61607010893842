import { useState } from "react";
import musicIcon from "../../../assets/icons/music.png";
import soundIcon from "../../../assets/icons/sound.png";
import Modal from "../modal/Modal";
import "./AudioSettings.css";
import SliderInput from "../sliderInput/SliderInput";

interface AudioSettingsProps {
  isOpen: boolean;
  onClose: () => void;
}

const AudioSettings: React.FC<AudioSettingsProps> = ({ isOpen, onClose }) => {
  const deviceType = localStorage.getItem("deviceType");
  const [musicVolume, setMusicVolume] = useState(0);
  const [soundVolume, setSoundVolume] = useState(0);

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Settings">
      <div className="flex flex-col w-full">
        <div className="flex w-full justify-start items-center">
          <img
            src={musicIcon}
            alt="Music"
            className={
              deviceType === "desktop"
                ? "w-[126px] h-[126px]"
                : "w-[90px] h-[90px]"
            }
          />
          <div
            className={`control-label mr-8 ${
              deviceType === "desktop"
                ? "text-[32px] font-bold"
                : "text-[16px] font-semibold"
            }`}
          >
            MUSIC
          </div>
          <SliderInput
            id="music"
            min={0}
            max={100}
            initialValue={musicVolume}
            onChange={(val: number) => setMusicVolume(val)}
          ></SliderInput>
        </div>
        <div className="flex w-full justify-start items-center">
          <img
            src={soundIcon}
            alt="Sound"
            className={
              deviceType === "desktop"
                ? "w-[126px] h-[126px]"
                : "w-[90px] h-[90px]"
            }
          />
          <div
            className={`control-label mr-8 ${
              deviceType === "desktop"
                ? "text-[32px] font-bold"
                : "text-[16px] font-semibold"
            }`}
          >
            SOUND
          </div>
          <SliderInput
            id="sound"
            min={0}
            max={100}
            initialValue={soundVolume}
            onChange={(val: number) => setSoundVolume(val)}
          ></SliderInput>
        </div>
      </div>
    </Modal>
  );
};

export default AudioSettings;
