import React from "react";
import { TableDataMap } from "./constants/tablesData";
import favIcon from "../../assets/icons/fav_icon.png";
import favActiveIcon from "../../assets/icons/fav_active_icon.png";
import { Table } from "../../store/tablesModel";
import axios from "axios";
import { favTablesLoaded } from "../../store/favTablesSlice";
import { useDispatch } from "react-redux";

interface GameTabProps {
  table: Table;
  favTables: string[];
  onGameClick: (gameId: string) => void;
}

const GameTab: React.FC<GameTabProps> = ({ table, favTables, onGameClick }) => {
  const deviceType = localStorage.getItem("deviceType");
  const dispatch = useDispatch();

  const handleFavClick = async () => {
    try {
      const response = await axios.post(
        "https://backend.inferixai.link/api/favorites",
        {
          tableId: table.tableId,
          isFav: !favTables.includes(table.tableId),
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      );
      if (response.data && response.data.status === "RS_OK") {
        // Dispatch to Redux store
        const _fTables = response.data.favTables;
        dispatch(favTablesLoaded(_fTables));
      } else {
        throw new Error(response.data.message);
      }
    } catch (err: any) {
      console.error(err);
    }
  };

  return (
    <div
      className={`relative flex z-10 w-[48%] justify-end mt-2 mb-2 sm:w-[22.5%] sm:ml-[18px] ${
        deviceType === "desktop" && "hover:scale-[1.075]"
      }`}
      key={table.tableId + "_game"}
    >
      <img
        className="cursor-pointer shadow-md my-auto rounded-xl"
        src={TableDataMap[table.slug as keyof typeof TableDataMap].tableImage}
        alt={table.tableId}
        key={table.tableId + "_game_img"}
        onClick={() => onGameClick(table.slug)}
      />
      <img
        className={`cursor-pointer p-1 absolute ${
          deviceType === "desktop"
            ? `w-[40px] ${
                TableDataMap[table.slug as keyof typeof TableDataMap]
                  .favIconPositionWeb
              } mr-[2.8%]`
            : `w-[32px] ${
                TableDataMap[table.slug as keyof typeof TableDataMap]
                  .favIconPositionMob
              } mr-[2%]`
        }`}
        src={favTables.includes(table.tableId) ? favActiveIcon : favIcon}
        alt="favorite"
        onClick={handleFavClick}
      />
    </div>
  );
};

export default GameTab;
