import { useDispatch, useSelector } from "react-redux";
import allGamesIcon from "../../assets/icons/all_games.png";
import favGamesIcon from "../../assets/icons/fav_games.png";
import slotGamesIcon from "../../assets/icons/slots.png";
import { tablesFilterUpdated } from "../../store/filtersSlice";
import { Filters } from "../../store/filtersModel";
import { RootState } from "../../store/store";
import { useNavigate } from "react-router-dom";

type SidePanelItem = {
  id: string;
  icon: any;
  name: string;
  slug: Filters["tables"];
};

export default function SidePanel() {
  const deviceType = localStorage.getItem("deviceType");
  const filters = useSelector((state: RootState) => state.filters);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const items: SidePanelItem[] = [
    {
      id: "all",
      icon: allGamesIcon,
      name: "All",
      slug: "all",
    },
    {
      id: "favs",
      icon: favGamesIcon,
      name: "Favourite",
      slug: "favs",
    },
    {
      id: "slots",
      icon: slotGamesIcon,
      name: "Slots",
      slug: "slots",
    },
  ];

  const handleFilterChange = (_tablesFilter: Filters["tables"]) => {
    if (_tablesFilter !== filters.tables) {
      dispatch(tablesFilterUpdated(_tablesFilter));
      navigate(
        `/lobby?filters=${encodeURIComponent(
          JSON.stringify({ tables: _tablesFilter })
        )}`
      );
    }
  };

  return (
    <div
      className={`flex flex-col justify-around m-auto bg-[url(./assets/side_panel_bg.png)] bg-no-repeat bg-top bg-cover overflow-hidden ${
        deviceType === "desktop"
          ? "w-[148px] h-[468px] pt-20 pb-8"
          : "w-[88px] h-[288px] pt-12 pb-4"
      }`}
    >
      {items.map((item, idx) => (
        <div className="flex flex-col" key={item.id + "_ctn"}>
          <img
            key={item.id + "_icon"}
            src={item.icon}
            alt={item.id}
            onClick={() => handleFilterChange(item.slug)}
            className={`cursor-pointer mx-auto ${
              filters.tables === item.slug && "scale-[1.08]"
            } ${
              deviceType === "desktop"
                ? "w-[90px] h-[90px] hover:scale-[1.08]"
                : "w-[55px] h-[55px]"
            } ${idx === 0 && "mt-3"} `}
          />
          <div
            key={item.id + "_name"}
            className={`flex w-fit mx-auto ${
              deviceType === "desktop"
                ? "text-sm font-medium -mt-[10px]"
                : "text-[10px] font-normal -mt-2"
            } ${
              filters.tables === item.slug
                ? "scale-[1.12] text-[#d4a354]"
                : "text-white"
            }`}
          >
            {item.name}
          </div>
        </div>
      ))}
    </div>
  );
}
