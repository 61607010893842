import { configureStore } from '@reduxjs/toolkit'
import tablesReducer from './tablesSlice'
import favTablesReducer from './favTablesSlice'
import filtersReducer from './filtersSlice'

export const store = configureStore({
  reducer: {
    tables: tablesReducer,
    favTables: favTablesReducer,
    filters: filtersReducer
  },
  devTools: true
});

// Define RootState type based on the state of the store
export type RootState = ReturnType<typeof store.getState>;

// Optionally define AppDispatch type if needed
export type AppDispatch = typeof store.dispatch;
