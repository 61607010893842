import { useEffect, useState } from "react";
import profileIcon from "../../../assets/icons/profile.png";
import dollarIcon from "../../../assets/icons/dollar_sign.png";
import menuIcon from "../../../assets/icons/menu.png";
import settingsIcon from "../../../assets/icons/settings.png";
import "./TopPanel.css";
import AudioSettings from "../audioSettings/AudioSettings";

export default function TopPanel() {
  const deviceType = localStorage.getItem("deviceType");
  const [balance, setBalance] = useState();
  const [isAudioSettingsOpen, setIsAudioSettingsOpen] =
    useState<boolean>(false);

  useEffect(() => {
    const token = sessionStorage.getItem("token");
    if (!token) {
      console.error("No token found in session storage.");
      return;
    }

    const ws = new WebSocket(
      `wss://backend.inferixai.link/user/auth?authorization=Bearer ${token}`
    );

    ws.onopen = () => {
      console.log("WebSocket connection opened.");

      // Request balance immediately upon connection
      ws.send(JSON.stringify({ operation: "getbalance" }));

      // Request balance every 10 seconds
      const intervalId = setInterval(() => {
        ws.send(JSON.stringify({ operation: "getbalance" }));
      }, 10000);

      ws.onmessage = (event) => {
        const data = JSON.parse(event.data);
        const balanceData = data.data;
        if (balanceData && balanceData.balance !== undefined) {
          setBalance(balanceData.balance);
        }
      };

      ws.onclose = () => {
        console.log("WebSocket connection closed.");
        clearInterval(intervalId);
      };

      ws.onerror = (error) => {
        console.error("WebSocket error:", error);
        clearInterval(intervalId);
      };

      // Cleanup on unmount
      return () => {
        clearInterval(intervalId);
        ws.close();
      };
    };
  }, []);

  return (
    <div
      className={`flex flex-col bg-[url(./assets/top_panel.png)] bg-no-repeat bg-top bg-cover p-0 pb-2 pt-[6px] z-[100] ${
        deviceType === "desktop" ? "h-[125px]" : "h-[85px]"
      }`}
    >
      <div className="flex w-full h-full justify-between items-baseline">
        <div className="flex justify-start ml-2 items-baseline">
          <div
            className={`flex items-center ${
              deviceType === "desktop" ? "mr-6" : "mr-3"
            }`}
          >
            <img
              src={profileIcon}
              className={`mr-2 ${
                deviceType === "desktop"
                  ? "w-[90px] h-[90px]"
                  : "w-[55px] h-[55px]"
              }`}
              alt="profile"
            />
            <div
              className={`font-semibold gradient-text cursor-default ${
                deviceType === "desktop" ? "text-xl" : "text-sm"
              }`}
            >
              ID: {sessionStorage.getItem("username")}
            </div>
          </div>
          <div className="flex items-center">
            <img
              src={dollarIcon}
              className={`mr-2 ${
                deviceType === "desktop"
                  ? "w-[90px] h-[90px]"
                  : "w-[55px] h-[55px]"
              }`}
              alt="$"
            />
            <div
              className={`font-semibold gradient-text cursor-default ${
                deviceType === "desktop"
                  ? "-ml-5 text-[32px]"
                  : "-ml-[14px] text-xl"
              }`}
            >
              {balance}
            </div>
          </div>
        </div>
        <div className="flex justify-end pr-2">
          <img
            className={`mr-2 cursor-pointer ${
              deviceType === "desktop"
                ? "w-[90px] h-[90px]"
                : "w-[55px] h-[55px]"
            }`}
            src={menuIcon}
            alt="menu"
          />
          <img
            src={settingsIcon}
            onClick={() => setIsAudioSettingsOpen(true)}
            className={`cursor-pointer ${
              deviceType === "desktop"
                ? "w-[90px] h-[90px] hover:scale-[1.08]"
                : "w-[55px] h-[55px]"
            }`}
            alt="settings"
          />
        </div>
      </div>

      {isAudioSettingsOpen && (
        <AudioSettings
          isOpen={isAudioSettingsOpen}
          onClose={() => setIsAudioSettingsOpen(false)}
        ></AudioSettings>
      )}
    </div>
  );
}
