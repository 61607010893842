import rotatePhoneToLandscapeIcon from "../../assets/icons/rotatePhoneToLandscapeIcon.png";
import rotatePhoneToPortraitIcon from "../../assets/icons/rotatePhoneToPortraitIcon.png";

interface RotatePhoneProps {
  orientation: "landscape-primary" | "portrait-primary";
}

const RotatePhone: React.FC<RotatePhoneProps> = ({ orientation }) => {
  return (
    <div className="flex flex-col w-full h-full justify-around p-4 bg-black/[0.8]">
      <div className="flex flex-col -mt-[20%] text-2xl font-bold text-center">
        <img
          className="mx-auto mb-6"
          src={
            orientation === "landscape-primary"
              ? rotatePhoneToLandscapeIcon
              : rotatePhoneToPortraitIcon
          }
          alt="rotate"
          height={64}
          width={64}
        />
        Please rotate your device to {orientation.replace("-primary", "")} mode
        for the best experience.
      </div>
    </div>
  );
};

export default RotatePhone;
